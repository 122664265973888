<template>
    <div id="download" class="pt-12 pb-12 w-full text-center">
        <h1 class="text-main-black font-semibold text-2xl">
            {{ $t("app.download") }}
        </h1>
        <div class="mt-4 opacity-50 text-sm md:block xs:hidden">
            {{ $t("app.download_desc") }}
        </div>
        <div class="flex justify-center">
            <a href="javascript:">
                <img
                    src="./../../../assets/app-store.svg"
                    alt=""
                    class="h-32 mr-6"
            /></a>
            <a
                href="https://play.google.com/apps/testing/com.tarotworld.im.tim"
            >
                <img
                    src="./../../../assets/google-play.svg"
                    alt=""
                    class="h-32"
            /></a>
        </div>
    </div>
</template>

<script>
export default {
    name: "Download",
}
</script>

<style scoped></style>

<template>
    <div id="testimonials" class="bg-grey-lightest pt-12 pb-12">
        <carousel
            :per-page="1"
            class="text-black"
            autoplay
            loop
            paginationColor="#000000"
            paginationActiveColor="#1f8a65"
        >
            <slide v-for="testimonial in testimonials" :key="testimonial.id">
                <div class="text-center">
                    <div class="text-2xl italic">
                        {{ testimonial.title }}
                    </div>
                    <p class="text-black font-base text-md opacity-50 m-10">
                        {{ testimonial.message }}
                    </p>
                    <div class="inline-flex">
                        <img
                            :src="'avatars/' + testimonial.icon"
                            alt="avatar"
                            class="h-16"
                        />
                        <div class="text-black mt-3 ml-6">
                            <div class="font-medium text-lg">
                                {{ testimonial.name }}
                            </div>
                            <div class="font-thin text-md opacity-75">
                                {{ testimonial.job }}
                            </div>
                        </div>
                    </div>
                </div>
            </slide>
        </carousel>
    </div>
    <!-- </div> -->
</template>

<script>
export default {
    data() {
        return {
            testimonials: [
                {
                    name: this.$t("suggest.first.name"),
                    job: this.$t("suggest.first.professional"),
                    title: this.$t("suggest.first.title"),
                    message: this.$t("suggest.first.content"),
                    icon: "male-1.png",
                },
                {
                    name: this.$t("suggest.second.name"),
                    job: this.$t("suggest.second.professional"),
                    title: this.$t("suggest.second.title"),
                    message: this.$t("suggest.second.content"),
                    icon: "female-1.png",
                },
                {
                    name: this.$t("suggest.third.name"),
                    job: this.$t("suggest.third.professional"),
                    title: this.$t("suggest.third.title"),
                    message: this.$t("suggest.third.content"),
                    icon: "male-2.png",
                },
            ],
        }
    },
    mounted() {
        // axios
        //     .get("testimonials.json")
        //     .then((response) => (this.testimonials = response.data))
    },
}
</script>

<template>
    <div class="flex items-center flex-no-shrink text-white mr-6">
        <img
            src="./../../../assets/logo.png"
            alt="logo"
            class="block h-8 mr-2"
        />
        <span class="font-bold text-3xl font-sans -mb-2">TarotWorld</span>
    </div>
</template>

<script>
export default {}
</script>

<style scoped></style>

<template>
    <div
        class="font-sans antialiased leading-tight p-0 xs:-mr-5 w-full overflow"
    >
        <!-- <div class="bg-main-green text-center py-4 lg:px-4">
        <a href="#" class="no-underline">
            <div class="p-2 bg-green-darker items-center text-green-lightest leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                <span class="flex rounded-full bg-green uppercase px-2 py-1 text-xs font-bold mr-3">New</span>
                <span class="font-semibold mr-2 text-left flex-auto">Please checkout our new version</span>
                <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
            </div>
        </a>
    </div> -->
        <app-header></app-header>
        <app-features></app-features>
        <app-download></app-download>
        <app-testimonials></app-testimonials>
        <app-contact></app-contact>
        <app-footer></app-footer>
    </div>
</template>

<script>
import AppHeader from "./components/app_header"
import AppFeatures from "./components/app_features"
import AppTestimonials from "./components/app_testimonials"
import AppFooter from "./components/app_footer"
import AppContact from "./components/app_contact"
import AppDownload from "./components/app_download"
export default {
    components: {
        AppHeader,
        AppFeatures,
        AppTestimonials,
        AppFooter,
        AppContact,
        AppDownload,
    },
}
</script>

import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import i18n from "../i18n/index"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "page.home",
        },
    },
    {
        path: "/agreement/privacy",
        name: "privayAgreement",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "agreement" */ "../views/agreement/privacy.vue"
            ),
        meta: {
            title: "page.privacyAgreement",
        },
    },
    {
        path: "/agreement/use",
        name: "useAgreement",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "agreement" */ "../views/agreement/use.vue"
            ),
        meta: {
            title: "page.useAgreement",
        },
    },
]

const router = new VueRouter({
    routes,
})

router.beforeEach((to, from, next) => {
    console.log(to, to.meta, i18n.t(to.meta.title))
    document.title = i18n.t(to.meta.title)
    next()
})

export default router

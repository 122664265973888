<template>
    <div class="pt-24">
        <div class="bg-main-black">
            <div class="w-full flex justify-center text-center pt-8 opacity-25">
                <a href="#" v-scroll-to="'#app'"
                    ><img
                        src="./../../../assets/up-arrow.svg"
                        alt=""
                        class="h-6"
                /></a>
            </div>

            <div class="w-full text-center flex flex-col items-center">
                <div>
                    <img
                        src="./../../../assets/logo.png"
                        alt="logo"
                        class="h-16 mt-8"
                    />
                </div>
                <div class="text-grey-lightest fond-bold text-2xl">
                    TarotWorld
                </div>
            </div>
            <div
                class="
                    flex
                    items-start
                    justify-center
                    text-center text-grey-lightest
                    mt-24
                "
            >
                <div class="md:w-1/5 xs:w-1/3">
                    <div class="font-bold text-lg">
                        {{ $t("menu.feature") }}
                    </div>
                    <div
                        class="
                            flex flex-col
                            items-center
                            font-thin
                            text-sm
                            opacity-50
                            mt-4
                        "
                    >
                        <a
                            href="javascript:"
                            class="no-underline text-grey-lightest"
                            >Safe</a
                        >
                        <a
                            href="javascript:"
                            class="no-underline text-grey-lightest mt-2"
                            >Free</a
                        >
                        <a
                            href="javascript:"
                            class="no-underline text-grey-lightest mt-2"
                            >Fun</a
                        >
                    </div>
                </div>
                <div class="md:w-1/5 xs:w-1/3">
                    <div class="font-bold text-lg">
                        {{ $t("menu.download") }}
                    </div>
                    <div
                        class="
                            flex flex-col
                            items-center
                            font-thin
                            text-sm
                            opacity-50
                            mt-4
                        "
                    >
                        <a href="#" class="no-underline text-grey-lightest"
                            >{{ $t("menu.apple_store") }}
                            <span class="md:inline-block xs:hidden"
                                >(iOS)</span
                            ></a
                        >
                        <a href="#" class="no-underline text-grey-lightest mt-2"
                            >{{ $t("menu.google_store") }}
                            <span class="md:inline-block xs:hidden"
                                >(Android)</span
                            ></a
                        >
                    </div>
                </div>
                <div class="md:w-1/5 xs:w-1/3">
                    <div class="font-bold text-lg">
                        {{ $t("menu.company") }}
                    </div>
                    <div
                        class="
                            flex flex-col
                            items-center
                            font-thin
                            text-sm
                            opacity-50
                            mt-4
                        "
                    >
                        <!-- <a href="#" class="no-underline text-grey-lightest"
                            >About Us</a
                        >
                        <a href="#" class="no-underline text-grey-lightest mt-2"
                            >Jobs</a
                        >
                        <a href="#" class="no-underline text-grey-lightest mt-2"
                            >Sitemap</a
                        > -->
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap mt-12 items-center justify-between p-3">
                <div class="text-grey-lightest text-sm opacity-75">
                    designed and developed by
                    <a class="no-underline text-blue">siokas</a>
                </div>
                <div class="flex justify-between">
                    <img
                        src="./../../../assets/facebook.svg"
                        alt="facebook icon"
                        class="h-6 mr-6"
                    />
                    <img
                        src="./../../../assets/twitter.svg"
                        alt="twitter icon"
                        class="h-6 mr-6"
                    />
                    <img
                        src="./../../../assets/youtube.svg"
                        alt="youtube icon"
                        class="h-6"
                    />
                    <!-- <img src="./../../../assets/google-plus.svg" alt="googleplus icon" class="h-6"> -->
                    <!-- <img src="./../../../assets/linkedin.svg" alt="" class="h-6 mr-6"> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

import Vue from "vue"
import VueI18n from "vue-i18n"

Vue.use(VueI18n)

function loadLocaleMessages() {
    const locales = require.context(
        "./config",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    )
    const messages = {}
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

function get_language() {
    if (navigator.language) {
        var language = navigator.language
    } else {
        var language = navigator.browserLanguage
    }
    if (language.toLowerCase().includes("zh")) {
        return "zh"
    }
    if (language.toLowerCase().includes("kr")) {
        return "kr"
    }
    return "en"
}

export default new VueI18n({
    locale: get_language(),
    fallbackLocale: "zh",
    messages: loadLocaleMessages(),
})

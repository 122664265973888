import Vue from "vue"
import "./plugins/axios"
import "./plugins/carousel"
import "./plugins/scrollto"
import App from "./App.vue"
import store from "./store"
import "./assets/tailwind.css"
import i18n from "./i18n/index"
import router from "./router"

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app")

<template>
    <div id="features" class="mb-6">
        <div class="bg-grey-lightest">
            <div class="container mx-auto px-6 pt-32">
                <h1 class="text-center text-black font-semibold text-2xl">
                    {{ $t("app.feature.title") }}
                </h1>
                <div
                    class="
                        flex
                        md:flex-nowrap
                        xs:flex-wrap
                        items-center
                        justify-center
                        mt-24
                    "
                >
                    <div
                        class="
                            px-6
                            py-6
                            m-2
                            text-center
                            flex flex-col
                            itmes-center
                        "
                    >
                        <img
                            src="./../../../assets/mountain.svg"
                            :alt="$t('app.feature.free')"
                            class="h-16 mb-4"
                        />
                        <h3 class="mb-4">{{ $t("app.feature.free") }}</h3>
                        <p class="text-black font-base text-sm opacity-50">
                            {{ $t("app.feature.free_desc") }}
                        </p>
                    </div>
                    <div
                        class="
                            px-6
                            py-6
                            m-2
                            text-center
                            flex flex-col
                            itmes-center
                        "
                    >
                        <img
                            src="./../../../assets/tent.svg"
                            :alt="$t('app.feature.safe')"
                            class="h-16 mb-4"
                        />
                        <h3 class="mb-4">{{ $t("app.feature.safe") }}</h3>
                        <p class="text-black font-base text-sm opacity-50">
                            {{ $t("app.feature.safe_desc") }}
                        </p>
                    </div>
                    <div
                        class="
                            px-6
                            py-6
                            m-2
                            text-center
                            flex flex-col
                            itmes-center
                        "
                    >
                        <img
                            src="./../../../assets/picnic.svg"
                            :alt="$t('app.feature.funny')"
                            class="h-16 mb-4"
                        />
                        <h3 class="mb-4">{{ $t("app.feature.funny") }}</h3>
                        <p class="text-black font-base text-sm opacity-50">
                            {{ $t("app.feature.funny_desc") }}
                        </p>
                    </div>
                </div>
                <div
                    class="
                        flex
                        md:flex-nowrap
                        xs:flex-wrap
                        items-center
                        justify-center
                        lg:mt-64
                        xs:mt-0
                    "
                >
                    <div class="flex-1">
                        <div
                            class="
                                px-12
                                py-12
                                m-4
                                text-center
                                flex flex-col
                                itmes-center
                            "
                        >
                            <img
                                src="./../../../assets/compass.svg"
                                :alt="$t('app.feature.real')"
                                class="h-16 mb-4"
                            />
                            <h3 class="mb-4">{{ $t("app.feature.real") }}</h3>
                            <p class="text-black font-thin text-xs opacity-50">
                                {{ $t("app.feature.real_desc") }}
                            </p>
                        </div>
                        <div
                            class="
                                px-12
                                py-12
                                m-4
                                text-center
                                flex flex-col
                                itmes-center
                            "
                        >
                            <img
                                src="./../../../assets/canteen.svg"
                                :alt="$t('app.feature.airplane')"
                                class="h-16 mb-4"
                            />
                            <h3 class="mb-4">
                                {{ $t("app.feature.airplane") }}
                            </h3>
                            <p class="text-black font-thin text-xs opacity-50">
                                {{ $t("app.feature.airplane_desc") }}
                            </p>
                        </div>
                    </div>
                    <div class="xs:hidden lg:block md:flex-2 lg:flex-2">
                        <img
                            src="./../../../assets/iphone.png"
                            alt="iphone image"
                            class="h-iphone"
                        />
                    </div>
                    <div class="flex-1">
                        <div
                            class="
                                px-12
                                py-12
                                m-4
                                text-center
                                flex flex-col
                                itmes-center
                            "
                        >
                            <img
                                src="./../../../assets/forest.svg"
                                :alt="$t('app.feature.hole')"
                                class="h-16 mb-4"
                            />
                            <h3 class="mb-4">{{ $t("app.feature.hole") }}</h3>
                            <p class="text-black font-thin text-xs opacity-50">
                                {{ $t("app.feature.hole_desc") }}
                            </p>
                        </div>
                        <div
                            class="
                                px-12
                                py-12
                                m-4
                                text-center
                                flex flex-col
                                itmes-center
                            "
                        >
                            <img
                                src="./../../../assets/pines.svg"
                                :alt="$t('app.feature.decoration')"
                                class="h-16 mb-4"
                            />
                            <h3 class="mb-4">
                                {{ $t("app.feature.decoration") }}
                            </h3>
                            <p class="text-black font-thin text-xs opacity-50">
                                {{ $t("app.feature.decoration_desc") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <svg
            id="curveDownColor"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="100%"
            height="100"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
        >
            <path style="fill: #f8fafc" d="M0 0 C 50 100 80 100 100 0 Z" />
        </svg>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
}
</script>
